"use client"

import PublicFacingComponentSelector from "@/components/ScreenSelector/PublicFacingComponentSelector"
import ScrollToTop from "@/components/ScrollToTop"
import ShowWhenNotVisible from "@/components/ShowWhenNotVisible"
import ManageCatalogue from "@/components/Template/components/ManageCatalogue/builder"
import { WebstoreTheme } from "@/enums"
import {
	FooterBar,
	PublicFooterLayout,
	FooterLayoutBuilder,
	Hero,
	HeroBuilder,
	HeroContent,
	HeroContentBuilder,
	Navbar,
} from "@/templateComponents"
import { Product, Service, WebstoreConfig } from "@/types"
import { useTheme } from "@mui/material"
import Divider from "@mui/material/Divider"
import React from "react"
import TemplateComponentThemeProvider from "./TemplateThemeProvider"
import CartProvider from "@/hooks/useCart"

type Props = {
	/**
	 * The template uses props when rendering to merchants' clients.
	 * This is because no config values need to change.
	 *
	 * If the template is in buidler mode, it'll use the builder state.
	 */
	publicFacingWebstoreConfig: Omit<WebstoreConfig, "currentStep">

	webstoreName: string

	products: Product[]

	services: Service[]

	isPublicFacing: boolean
}

const Template = (props: Props) => {
	const { palette } = useTheme()
	const hero = props.publicFacingWebstoreConfig.hero
	const themeName = props.publicFacingWebstoreConfig.colorScheme ?? WebstoreTheme.DefaultTheme

	//  TO DO refactor to have HeroBuilder and Hero components
	return (
		<TemplateComponentThemeProvider themeName={themeName}>
			<CartProvider>
				<Navbar sticky={props.isPublicFacing} />
			</CartProvider>
			<PublicFacingComponentSelector isPublicFacing={props.isPublicFacing}>
				<ShowWhenNotVisible
					trackedComponent={
						<Hero imageUrl={hero?.imageUrl as string}>
							<HeroContent heroContent={hero ?? {}} />
						</Hero>
					}
					visibleComponent={<ScrollToTop />}
				/>
				<HeroBuilder>
					<HeroContentBuilder />
				</HeroBuilder>
			</PublicFacingComponentSelector>
			<ManageCatalogue {...props} />
			<Divider sx={{ height: 0, borderColor: palette.grey[200] }} />

			<PublicFacingComponentSelector isPublicFacing={props.isPublicFacing}>
				<PublicFooterLayout {...props} />
				<FooterLayoutBuilder />
			</PublicFacingComponentSelector>
			<Divider sx={{ height: 0, borderColor: palette.grey[200] }} />

			<FooterBar />
		</TemplateComponentThemeProvider>
	)
}

export default Template
