"use client"
import { useInView } from "framer-motion"
import { ReactNode, useRef, useState, useEffect } from "react"

interface ShowWhenVisibleProps {
	trackedComponent: ReactNode
	visibleComponent: ReactNode
}

const ShowWhenNotVisible = ({ trackedComponent, visibleComponent }: Readonly<ShowWhenVisibleProps>) => {
	const ref = useRef(null)
	const isInView = useInView(ref)
	const [hasInitialized, setHasInitialized] = useState(false) // to prevent the visibleComponent from showing on first render

	useEffect(() => {
		if (ref.current) {
			setHasInitialized(true)
		}
	}, [ref])

	return (
		<>
			<div ref={ref}>{trackedComponent}</div>
			{hasInitialized && !isInView && visibleComponent}
		</>
	)
}

export default ShowWhenNotVisible
