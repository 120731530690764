import MaxWidth from "@/components/MaxWidth"
import { Count } from "@/components/Template/components/ManageCatalogue/Desktop/Count"
import { Filter } from "@/components/Template/components/ManageCatalogue/Desktop/Filter"
import { Sort } from "@/components/Template/components/ManageCatalogue/Desktop/Sort"
import { TransparentDivider } from "@/components/TransparentDivider"
import { cardsCacheAtom, sortedCardsAtom } from "@/state"
import { defaultPageSize } from "@/utils"
import { Button } from "@mui/material"
import Stack from "@mui/material/Stack"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useAtomValue } from "jotai"
import * as React from "react"

type Props = {
	cards: React.ReactNode[]
	productsCount: number
	servicesCount: number
	isConfigMode: boolean
}
export default function Desktop(props: Readonly<Props>) {
	const [pageSize, setPageSize] = React.useState(defaultPageSize)

	const sortedCards = useAtomValue(sortedCardsAtom)
	const cardsCache = useAtomValue(cardsCacheAtom)

	return (
		<Stack direction="row" justifyContent="center" paddingX={"5rem"}>
			<MaxWidth>
				<Grid container spacing={3} direction={"row"} justifyContent="space-between" id={"black111"}>
					{!props.isConfigMode && (
						<Grid xs maxWidth="15rem" flex="30%">
							<Filter />
						</Grid>
					)}
					<Grid flex="70%">
						<Stack pl={{ md: "1rem", lg: "2rem" }} direction="row" justifyContent="space-between" alignItems="center">
							<Count
								productsCount={props.productsCount}
								servicesCount={props.servicesCount}
								catalogueCount={sortedCards.length}
							/>
							<Sort />
						</Stack>
						<TransparentDivider height=".75rem" />
						<Grid container direction="row" columns={cardsCache.length % 2 === 0 ? 4 : 3} rowGap={3}>
							{props.cards.slice(0, pageSize)}
						</Grid>
						{props.cards.length > pageSize && (
							<Stack alignItems="center" my="1rem">
								<Button
									onClick={() => setPageSize(pageSize + defaultPageSize)}
									variant="outlined"
									sx={{
										color: "text.primary",
										borderColor: "text.primary",
										textTransform: "none",
										borderRadius: ".625rem",
										":hover": {
											borderColor: "text.primary",
											backgroundColor: "white",
										},
									}}
								>
									Load more
								</Button>
							</Stack>
						)}
					</Grid>
				</Grid>
			</MaxWidth>
		</Stack>
	)
}
