import { selectedTypeAtom } from "@/state"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Typography from "@mui/material/Typography"
import { useAtomValue } from "jotai"
import React from "react"

type Props = {
	productsCount: number
	servicesCount: number
	catalogueCount: number
}
export function Count(props: Readonly<Props>) {
	const { productsCount, servicesCount, catalogueCount } = props

	const canFilterType = !!(productsCount && servicesCount)
	const selectedType = useAtomValue(selectedTypeAtom)

	const singleTypeCountLabel = `${selectedType}${catalogueCount > 1 ? "s" : ""}`
	const multiTypeCountLabel = "products & services"

	return (
		<Typography sx={alert.title}>
			{!canFilterType && `${catalogueCount} ${productsCount ? "Product" : "Service"}${catalogueCount > 1 ? "s" : ""}`}
			{canFilterType && `${catalogueCount} ${selectedType === "all" ? multiTypeCountLabel : singleTypeCountLabel}`}
		</Typography>
	)
}
