import { useMediaQuery, useTheme } from "@mui/material"
import React, { PropsWithChildren } from "react"

type ScreenSizeComponentProps = PropsWithChildren & {
	/**
	 * The media query that should return true for the desktop version.
	 * Defaults to `(min-width: ${theme.breakpoints.values.md}px)`
	 */
	mediaQuery?: string
}

/**
 * Render the correct version based on media query.
 * Pass the desktop version as the first child and the adaptive version as the second child.
 * @returns
 */
const ScreenSizeComponentSelector = ({ children, mediaQuery }: ScreenSizeComponentProps) => {
	const theme = useTheme()
	const isDesktop = useMediaQuery(mediaQuery ?? theme.breakpoints.up("sm"))

	const childrenArray = React.Children.toArray(children)
	const [Desktop, Adaptive] = childrenArray

	return <>{isDesktop ? Desktop : Adaptive}</>
}

export default ScreenSizeComponentSelector
