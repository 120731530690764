"use client"
import { EditItem } from "@/state"
import { Fab } from "@mui/material"
import { CaretUp } from "@phosphor-icons/react"
import { useEffect, useState } from "react"

const ScrollToTop = () => {
	const [heroElement, setHeroElement] = useState<Element | null>(null)

	useEffect(() => {
		const element = document.querySelector(`[data-scroll-to='${EditItem.HeroImage}']`)
		setHeroElement(element)
	}, [])

	const scrollToTop = () => {
		if (heroElement) {
			heroElement.scrollIntoView({ behavior: "smooth" })
		}
	}

	return (
		<Fab
			aria-label="scroll to top"
			onClick={scrollToTop}
			sx={{
				position: "fixed",
				bottom: "2.5rem",
				right: "1.5rem",
				zIndex: 1000,
			}}
		>
			<CaretUp size={24} color="inherit" />
		</Fab>
	)
}

export default ScrollToTop
