import TypeFilter from "@/components/Template/components/TypeFilter"
import { TransparentDivider } from "@/components/TransparentDivider"
import {
	canFilterTypeAtom,
	cardsCacheAtom,
	filterCountAtom,
	openFilterDrawerAtom,
	selectedCategoryAtom,
	selectedTypeAtom,
} from "@/state"
import { defaultCatalogueType, defaultCategory } from "@/utils"
import { getUniqueCategoriesList } from "@/utils/manageCatalogue"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import CloseIcon from "@mui/icons-material/Close"
import { Button } from "@mui/material"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Stack from "@mui/material/Stack"
import { alpha } from "@mui/material/styles"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Typography from "@mui/material/Typography"
import { useAtom, useAtomValue } from "jotai/index"
import * as React from "react"

export default function Filter() {
	const cards = useAtomValue(cardsCacheAtom)

	const [selectedType, setSelectedType] = useAtom(selectedTypeAtom)
	const [_selectedType, _setSelectedType] = React.useState(selectedType)

	const categories = getUniqueCategoriesList(
		cards.filter((c) => c.type === _selectedType || defaultCatalogueType === _selectedType),
	)

	React.useEffect(() => {
		_setSelectedCategory(defaultCategory)
	}, [_selectedType])

	const canFilterType = useAtomValue(canFilterTypeAtom)

	const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom)
	const [_selectedCategory, _setSelectedCategory] = React.useState(selectedCategory)

	const filterCount = useAtomValue(filterCountAtom)

	const handleClose = () => setOpenFilterDrawer(false)

	const handleClearAll = () => {
		setSelectedType(defaultCatalogueType)
		_setSelectedType(defaultCatalogueType)
		setSelectedCategory(defaultCategory)
		_setSelectedCategory(defaultCategory)
	}

	const [openFilterDrawer, setOpenFilterDrawer] = useAtom(openFilterDrawerAtom)

	React.useEffect(() => {
		if (openFilterDrawer) {
			_setSelectedType(selectedType)
			_setSelectedCategory(selectedCategory)
		}
	}, [openFilterDrawer])

	const disabled = selectedType === _selectedType && selectedCategory === _selectedCategory

	return (
		<SwipeableDrawer anchor="left" open={openFilterDrawer} onOpen={() => {}} onClose={handleClose}>
			<Stack height="100%" width="20rem">
				<Stack direction="row" justifyContent="space-between" p="1rem" pb="0">
					<Stack direction="row" gap={1} alignItems="center">
						<Typography sx={alert.title}>Filters</Typography>
						{!!filterCount && (
							<Stack
								height="1.25rem"
								width="1.25rem"
								justifyContent="center"
								alignItems="center"
								borderRadius="50%"
								bgcolor={palette.common.black}
							>
								<Typography variant="caption" color="white">
									{filterCount}
								</Typography>
							</Stack>
						)}
					</Stack>
					<Stack direction="row" gap={1}>
						{!!filterCount && (
							<Typography
								sx={{
									textDecoration: "underline",
									cursor: "pointer",
								}}
								variant="body1"
								color="text.primary"
								onClick={handleClearAll}
							>
								Clear all
							</Typography>
						)}
						<CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
					</Stack>
				</Stack>
				{canFilterType && (
					<Box p="1rem" pt="0">
						<TransparentDivider height="1rem" />
						<TypeFilter
							value={_selectedType}
							onChange={(updatedValue) => {
								_setSelectedCategory(defaultCategory)
								_setSelectedType(updatedValue)
							}}
						/>
					</Box>
				)}
				<Typography p=".5rem 1rem" variant="subtitle2">
					Categories
				</Typography>
				<Box flex="1" overflow="auto" px="1rem">
					<List>
						{categories.map((category) => (
							<ListItemButton
								key={category.value}
								selected={category.value === _selectedCategory}
								onClick={() => _setSelectedCategory(category.value)}
								sx={{
									"&.Mui-selected": {
										backgroundColor: alpha(palette.common.black, 0.08),
									},
									"&.Mui-selected:hover": {
										backgroundColor: alpha(palette.common.black, 0.08),
									},
									py: ".25rem",
								}}
							>
								<ListItemText primary={category.label} />
							</ListItemButton>
						))}
					</List>
				</Box>
				<Stack justifyContent="center" alignItems="center" boxShadow={`0 5px 5px 10px ${alpha(palette.common.black, 0.1)}`}>
					<Button
						onClick={() => {
							setSelectedType(_selectedType)
							setSelectedCategory(_selectedCategory)
							handleClose()
						}}
						sx={{
							backgroundColor: alpha(palette.common.black, 1),
							color: palette.common.white,
							m: "1rem",
							mt: ".5rem",
							width: "17rem",
						}}
						disabled={disabled}
					>
						Apply
					</Button>
				</Stack>
			</Stack>
		</SwipeableDrawer>
	)
}
