import Filter from "@/components/Template/components/ManageCatalogue/Adaptive/Filter"
import Sort from "@/components/Template/components/ManageCatalogue/Adaptive/Sort"
import { filterCountAtom, openFilterDrawerAtom, openSortDrawerAtom } from "@/state"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { button } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { CaretDown, SlidersHorizontal } from "@phosphor-icons/react"
import { useSetAtom } from "jotai"
import { useAtomValue } from "jotai/index"
import React from "react"

export function Controls() {
	const setOpenFilterDrawer = useSetAtom(openFilterDrawerAtom)
	const setOpenSortDrawer = useSetAtom(openSortDrawerAtom)

	const filterCount = useAtomValue(filterCountAtom)

	return (
		<Stack
			m="1rem"
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			border="1px solid"
			borderColor="divider"
			borderRadius=".375rem"
			sx={{ cursor: "pointer" }}
		>
			<Stack
				width="100%"
				direction="row"
				justifyContent="center"
				alignItems="center"
				gap={1}
				onClick={() => setOpenFilterDrawer(true)}
			>
				<SlidersHorizontal size={20} />
				<Typography sx={button.medium}>Filters</Typography>
				{!!filterCount && (
					<Stack
						height="1.25rem"
						width="1.25rem"
						justifyContent="center"
						alignItems="center"
						borderRadius="50%"
						bgcolor={palette.common.black}
					>
						<Typography variant="subtitle2" color="white">
							{filterCount}
						</Typography>
					</Stack>
				)}
			</Stack>

			<Divider orientation="vertical" variant="middle" sx={{ height: "2rem" }} />

			<Stack
				width="100%"
				direction="row"
				justifyContent="center"
				alignItems="center"
				gap={1}
				onClick={() => setOpenSortDrawer(true)}
			>
				<Typography sx={button.medium}>Sort</Typography>
				<CaretDown size={20} />
			</Stack>

			<Sort />
			<Filter />
		</Stack>
	)
}
