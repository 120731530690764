import React from "react"

type PublicFacingComponentProps = React.PropsWithChildren & {
	/**
	 * Determines if component rendered is public facing
	 */
	isPublicFacing: boolean
}

/**
 * Render the correct template depending upon if the webstore is public facing or site builder.
 * Pass the public facing component as the first child and the site builder componentas the second child.
 * @returns
 */
const PublicFacingComponentSelector = ({ children, isPublicFacing }: PublicFacingComponentProps) => {
	const childrenArray = React.Children.toArray(children)
	const [PublicFacing, SiteBuilder] = childrenArray

	return <>{isPublicFacing ? PublicFacing : SiteBuilder}</>
}

export default PublicFacingComponentSelector
